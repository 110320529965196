@import "icomoon-variables";

@font-face {
  font-family: "icomoon";
  src: url("#{$icomoon-font-path}/icomoon.eot");
  src: url("#{$icomoon-font-path}/icomoon.eot") format("embedded-opentype"),
    url("#{$icomoon-font-path}/icomoon.ttf") format("truetype"),
    url("#{$icomoon-font-path}/icomoon.woff") format("woff"),
    url("#{$icomoon-font-path}/icomoon.svg") format("svg");

  font-weight: normal;
  font-style: normal;
}

.icon-big {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 3em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-medium {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 2.2em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-normal {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross-white {
  &:before {
    content: $icon-cross-white;
  }
}
.icon-delete-yellow {
  &:before {
    content: $icon-delete-yellow;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-minus-black {
  &:before {
    content: $icon-minus-black;
  }
}
.icon-number-Copy-5 {
  &:before {
    content: $icon-number-Copy-5;
  }
}
.icon-ok-black {
  &:before {
    content: $icon-ok-black;
  }
}
.icon-ok-yellow {
  &:before {
    content: $icon-ok-yellow;
  }
}
.icon-plus-black-alone {
  &:before {
    content: $icon-plus-black-alone;
  }
}
.icon-plus-black {
  &:before {
    content: $icon-plus-black;
  }
}
.icon-plus-yellow {
  &:before {
    content: $icon-plus-yellow;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-left-black {
  &:before {
    content: $icon-left-black;
  }
}
.icon-left-yellow {
  &:before {
    content: $icon-left-yellow;
  }
}
