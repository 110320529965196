@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  padding: 0;
  margin: 0; }

img {
  max-width: 100%; }

/*
font-family: system-ui, -apple-system, "Open Sans", "Source+Sans+Pro",
		"Roboto", "Helvetica", sans-serif;
		*/
body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #272727; }

.btn-selected {
  background: #efc346; }

a {
  color: #efc346;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
  cursor: pointer; }

a:hover {
  color: #f4f4f4; }

a:active {
  color: #f4f4f4; }

a:link,
a:visited {
  color: #efc346; }

a.dark {
  color: #2f2f2f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
  cursor: pointer; }
  a.dark:hover {
    color: #2f2f2f; }
  a.dark:active {
    color: #2f2f2f; }
  a.dark:link, a.dark:visited {
    color: #2f2f2f; }

.accent {
  color: #efc346; }

.pad {
  padding: 20px; }

.full-w {
  width: 100%; }

.full-h {
  height: 100%; }

.logo {
  width: 176px;
  height: 50px;
  margin: 10px; }

.logo-login {
  width: auto; }

.bg_yellow {
  background-color: #efc346; }

.layout__login {
  align-self: center;
  width: 400px;
  max-width: 90%;
  color: #272727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em; }

.wrap {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh; }

.layout__body {
  width: 100vw;
  flex: 1 1;
  display: flex;
  max-height: 100%; }

.layout_v {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%; }

.layout__content {
  display: flex;
  flex-direction: row;
  overflow: hidden; }

.fh {
  flex: 1 1;
  display: flex; }

.layout_h {
  flex: 1 1;
  display: flex; }

.layout__columns {
  width: calc(100% / 4);
  border-right: 1px solid #f5f5f5;
  background-color: #fff;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  padding: 5px;
  overflow: scroll; }

.column_width {
  background-color: #fff; }

.column {
  padding-top: 2em;
  height: 100%;
  margin: 0;
  padding: 0; }

.header {
  background: #2f2f2f;
  color: #f4f4f4;
  padding: 0.4em;
  width: 100vw;
  max-height: 75px;
  flex-basis: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.8px;
  font-size: 14px;
  font-weight: 600; }

.floating_footer {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .floating_footer .label {
    letter-spacing: 0.8px;
    font-size: 14px; }

.footer {
  color: #272727;
  padding: 0.4em;
  width: 100vw;
  height: 50px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center; }

.status {
  background: #efc346;
  background: -webkit-gradient(linear, left bottom, left top, from(#f4bb00), to(#fcd142));
  background: -webkit-linear-gradient(bottom, #f4bb00 0%, #fcd142 100%);
  background: linear-gradient(0deg, #f4bb00 0%, #fcd142 100%);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  color: #272727;
  padding: 5px;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  position: relative; }

.status-text {
  margin: 0 5px; }

.status-content {
  margin-left: 0.5rem;
  margin-right: 1.5rem; }

.status-title {
  font-size: 1.3em;
  font-weight: bold; }

.status-subtitle {
  margin-top: 4px;
  font-size: 15px;
  text-transform: uppercase; }

.layout__summary {
  flex: 0 0 400px;
  overflow: hidden;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  z-index: 1; }

.summary_title {
  text-align: center;
  font-size: 1.1em;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 12px; }

.summary_total {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
  margin-top: 20px; }

.summary_currency, .summary_currency_striked {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px; }

.summary_currency_striked {
  font-size: 18px;
  text-decoration: line-through; }

.summary_list {
  width: 100%;
  background: #f9f9f9;
  height: calc( 100vh - 311px);
  flex: 1 1;
  overflow-y: scroll; }

.summary__header {
  background-color: #f9f9f9;
  flex: 0 0 50px; }

.summary_footer {
  width: 400px;
  color: #272727;
  background: #fff;
  height: 181px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.11);
  padding: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column; }

.summary_list__item_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 3;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.summary_list__item {
  position: relative;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #d8d8d8;
  margin: 0; }
  .summary_list__item .icon {
    align-self: flex-start;
    margin-top: 4px;
    width: 36px;
    font-size: 12px;
    text-align: center; }
  .summary_list__item .title {
    align-self: flex-start;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-align: left;
    margin-right: 10px; }
  .summary_list__item .price {
    align-self: flex-start;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-align: right;
    margin-right: 10px; }
  .summary_list__item .descr {
    margin-top: 4px;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
    text-transform: uppercase; }

.summary_list__item:hover {
  background: #f4f4f4; }

.square {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  display: inline-block; }

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block; }

.status__input {
  align-self: centr;
  justify-self: center;
  width: 100%;
  max-width: 250px;
  height: 40px;
  border: 1px solid #2f2f2f;
  outline: 0;
  box-shadow: inset 0;
  padding: 2px 10px;
  background: #efc346;
  margin: 10px; }

.result-message {
  font-size: 1.2em;
  color: #fc0; }

.redBorder {
  border-left: 2px solid red;
  border-right: 2px solid red; }

/*
$btn-radius: 41.5px;
$btn-action-radius: 30px;
*/
.btn-oval {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: #fcd142;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #2f2f2f;
  display: flex;
  justify-content: center;
  align-items: center; }

.btn-oval--white {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #2f2f2f;
  display: flex;
  justify-content: center;
  align-items: center; }

.btn-oval--dark {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(2.95%, #464646), to(#2f2f2f));
  background: -webkit-linear-gradient(top, #464646 2.95%, #2f2f2f 100%);
  background: linear-gradient(180deg, #464646 2.95%, #2f2f2f 100%);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center; }

.btn-action {
  cursor: pointer;
  height: 50px;
  width: 128px;
  border-radius: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe17f), to(#fcd142));
  background: -webkit-linear-gradient(top, #ffe17f 0%, #fcd142 100%);
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 1em;
  color: #2f2f2f;
  border-width: 0; }

.btn-action-summary {
  width: 167px; }

.btn-action--dark {
  cursor: pointer;
  display: inline-block;
  height: 50px;
  width: 128px;
  border-radius: 0;
  border-width: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(2.95%, #464646), to(#2f2f2f));
  background: -webkit-linear-gradient(top, #464646 2.95%, #2f2f2f 100%);
  background: linear-gradient(180deg, #464646 2.95%, #2f2f2f 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #f8d23f;
  font-size: 1em; }

.btn-action--light {
  cursor: pointer;
  display: inline-block;
  height: 50px;
  width: 128px;
  border-radius: 0;
  border-width: 0;
  background: #d8d8d8;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #2f2f2f;
  font-size: 1em; }

.btn {
  cursor: pointer;
  height: 50px;
  border-radius: 0;
  font-size: 1em;
  color: #2f2f2f;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: 500;
  display: block;
  border-width: 0; }

.btn-small {
  width: 128px; }

.btn-big {
  width: 167px; }

.btn-block {
  width: 100%; }

.btn-squared {
  width: 50px; }

.btn-selected {
  cursor: pointer;
  height: 50px;
  border-radius: 0;
  font-size: 1em;
  color: #2f2f2f;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe17f), to(#fcd142));
  background: -webkit-linear-gradient(top, #ffe17f 0%, #fcd142 100%);
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  font-weight: bold;
  opacity: 1;
  border-width: 0; }

.btn-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em;
  margin-right: 0.5em; }
  .btn-content div {
    width: 15%;
    text-align: left; }
  .btn-content span {
    width: 30%;
    text-align: left; }

.btn-step {
  cursor: pointer;
  width: calc(100% - 1.6em);
  margin: 0.75em auto;
  max-width: 100%;
  height: 50px;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: 700;
  display: block;
  font-size: 1em;
  color: #2f2f2f;
  opacity: 1;
  border-width: 0; }

.btn-step-dimmed {
  opacity: 0.5; }

.btn-step-selected {
  cursor: pointer;
  width: calc(100% - 1.6em);
  height: 50px;
  margin: 0.75em auto;
  max-width: 100%;
  border-radius: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe17f), to(#fcd142));
  background: -webkit-linear-gradient(top, #ffe17f 0%, #fcd142 100%);
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #2f2f2f;
  font-weight: 700;
  display: block;
  border-width: 0;
  opacity: 1;
  color: #2f2f2f; }

.btn-step-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em; }
  .btn-step-content .icon {
    font-size: 2.5em; }

.btn-link {
  color: #efc346;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 1em;
  font-weight: 700; }
  .btn-link :hover {
    color: #f4f4f4; }
  .btn-link :active {
    color: #f4f4f4; }
  .btn-link :visited {
    color: #efc346; }

.btn-link-dark {
  color: #111;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 1em;
  font-weight: 700; }
  .btn-link-dark :hover {
    color: #333; }
  .btn-link-dark :active {
    color: #333; }
  .btn-link-dark :visited {
    color: #111; }

.btn-simple {
  color: #111;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  background: white;
  border: 1px solid #ddd; }
  .btn-simple :hover {
    color: #333;
    background: #f4f4f4; }
  .btn-simple :active {
    color: #333;
    background: white; }
  .btn-simple :visited {
    color: #111;
    background: white; }

.btn-round, .btn-round--dark {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  background-color: #fcd142;
  border: none;
  border-radius: 50%;
  font-size: 10;
  color: #111;
  fill: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin: auto 10px; }
  .btn-round svg, .btn-round--dark svg {
    width: 20px;
    height: 20px; }

.btn-round:hover, .btn-round--dark:hover {
  border: 1px solid #f4f4f4; }

.btn-round--dark {
  background-color: #2f2f2f;
  font-size: 10;
  color: #fff; }

.btn-round--dark:hover {
  border: 1px solid #fff; }

button,
.btn,
.btn-simple,
.btn-link,
.btn-link-dark,
.btn-selected,
.btn-action,
.btn-action--dark,
.btn-oval,
.btn-oval--dark,
.btn-step,
.btn-step--checked,
.btn-action:focus {
  outline: 2;
  cursor: pointer; }

.calculator {
  height: 365px;
  width: 270px;
  border-radius: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe17f), to(#fcd142));
  background: -webkit-linear-gradient(top, #ffe17f 0%, #fcd142 100%);
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1); }

.calculator__top-bar {
  height: 71px;
  width: 270px;
  border-radius: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(#eaeaea), to(#ffffff));
  background: -webkit-linear-gradient(bottom, #eaeaea 0%, #ffffff 100%);
  background: linear-gradient(0deg, #eaeaea 0%, #ffffff 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  padding: 10px auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.calculator__display {
  color: #2f2f2f;
  flex: 1 1;
  font-size: 1.8em;
  overflow: auto;
  margin: 0.5em 0.2em;
  text-align: center; }

.calculator__keys {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em; }

.calculator__row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px; }

.inactive {
  opacity: 0.5; }

.calculator__op {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border-width: 2px;
  color: #2f2f2f;
  border-color: #2f2f2f;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin: 4px 26px 5px;
  font-weight: bold;
  font-size: 16px; }

.calculator__num {
  background: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  height: 52px;
  width: 52px;
  border-width: 2px;
  color: #2f2f2f;
  border-color: #2f2f2f;
  border-radius: 50%;
  margin: 12px;
  font-weight: bold;
  font-size: 13px; }

/*
._calculator {
  background-color: #eee;
  border-radius: 1em;
  width: 16em;
}

._calculator__top-bar {
  border-radius: 1em 1em 0 0;
  width: 100%;
  height: 5em;
  background: #fff;

  padding: 1em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

._calculator__num {
  border-width: 1px;
  color: $font-color;
  border-color: $border-color;
  line-height: 3em;
  border-radius: 50%;
  height: 3.8em;
  width: 3.8em;
  margin: 0.5em;
}
*/
.calc_input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 1em;
  text-align: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0; }

.modal-container {
  position: relative;
  display: none; }
  .modal-container .modal-content,
  .modal-container .modal-backdrop {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in; }
  .modal-container .modal-content-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 0 0.4em;
    padding: 0 0.4em;
    background: #232527;
    color: #fff;
    width: auto;
    border-radius: 0.4em;
    font-size: 0.8em;
    height: 2em;
    width: auto;
    min-width: 4em;
    font-weight: normal; }
    .modal-container .modal-content-btn:hover {
      background: #17181a; }
  .modal-container .modal-content-footer {
    margin: 10px; }
  .modal-container .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
  .modal-container .modal-content-body {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  .modal-container.active {
    display: block; }
    .modal-container.active .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      visibility: visible;
      opacity: 1;
      -webkit-transition: opacity 0.2s ease-in;
      transition: opacity 0.2s ease-in;
      display: flex;
      justify-content: center;
      align-items: center; }
    .modal-container.active .modal-content {
      color: #111;
      position: relative;
      opacity: 1;
      background-color: #fff;
      max-width: 80%;
      max-height: 90%;
      min-width: 36em;
      min-height: 24em;
      height: auto;
      width: auto;
      padding: 0.6em 2em;
      border-radius: 0.4em;
      z-index: 9;
      pointer-events: auto;
      cursor: auto;
      visibility: visible;
      box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.6); }
      .modal-container.active .modal-content .modal-close {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        color: #2f2f2f;
        font-weight: bold;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center; }
    .modal-container.active .modal-overlay {
      color: #fff;
      position: relative;
      opacity: 1;
      background: none;
      max-width: 80%;
      max-height: 90%;
      width: 42em;
      height: 30em;
      padding: 0.6em 2em;
      border-radius: 0.4em;
      z-index: 9;
      pointer-events: auto;
      cursor: auto;
      visibility: visible; }
      .modal-container.active .modal-overlay .modal-close {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        color: #2f2f2f;
        font-weight: bold;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center; }

.react_icon {
  height: 2em;
  width: 48px; }

@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.2bc67c52.eot);
  src: url(/static/media/icomoon.2bc67c52.eot) format("embedded-opentype"), url(/static/media/icomoon.7619b8b4.ttf) format("truetype"), url(/static/media/icomoon.577c7130.woff) format("woff"), url(/static/media/icomoon.186042b3.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-big {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 3em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-medium {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 2.2em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-normal {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-cross-white:before {
  content: ""; }

.icon-delete-yellow:before {
  content: ""; }

.icon-delete:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-minus-black:before {
  content: ""; }

.icon-number-Copy-5:before {
  content: ""; }

.icon-ok-black:before {
  content: ""; }

.icon-ok-yellow:before {
  content: ""; }

.icon-plus-black-alone:before {
  content: ""; }

.icon-plus-black:before {
  content: ""; }

.icon-plus-yellow:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-left-black:before {
  content: ""; }

.icon-left-yellow:before {
  content: ""; }

