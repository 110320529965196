$icomoon-font-path: "../fonts" !default;

$icon-cross-white: "\e900";
$icon-delete-yellow: "\e901";
$icon-delete: "\e902";
$icon-email: "\e903";
$icon-minus-black: "\e904";
$icon-number-Copy-5: "\e905";
$icon-ok-black: "\e906";
$icon-ok-yellow: "\e907";
$icon-plus-black-alone: "\e908";
$icon-plus-black: "\e909";
$icon-plus-yellow: "\e90a";
$icon-print: "\e90b";
$icon-left-black: "\e90c";
$icon-left-yellow: "\e90d";
