$font-color: #2f2f2f;
$border-color: #2f2f2f;

.calculator {
  height: 365px;
  width: 270px;
  border-radius: 0;
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
}

.calculator__top-bar {
  height: 71px;
  width: 270px;
  border-radius: 0;
  background: linear-gradient(0deg, #eaeaea 0%, #ffffff 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);

  padding: 10px auto;
  overflow: hidden;
  @include flex;
}

.calculator__display {
  color: $font-color;
  flex: 1;
  font-size: 1.8em;
  overflow: auto;
  margin: 0.5em 0.2em;
  text-align: center;
}

.calculator__keys {
  @include flex;
  flex-direction: column;
  padding: 1em;
}

.calculator__row {
  @include flex;
  height: 64px;
}
.inactive {
  opacity: 0.5;
}

.calculator__op {
  @include flex;

  background: rgba(0, 0, 0, 0);
  border-width: 2px;
  color: $font-color;
  border-color: $border-color;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin: 4px 26px 5px;
  font-weight: bold;
  font-size: 16px;
}

.calculator__num {
  background: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  height: 52px;
  width: 52px;
  border-width: 2px;
  color: $font-color;
  border-color: $border-color;

  border-radius: 50%;
  margin: 12px;
  font-weight: bold;
  font-size: 13px;
}
/*
._calculator {
  background-color: #eee;
  border-radius: 1em;
  width: 16em;
}

._calculator__top-bar {
  border-radius: 1em 1em 0 0;
  width: 100%;
  height: 5em;
  background: #fff;

  padding: 1em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

._calculator__num {
  border-width: 1px;
  color: $font-color;
  border-color: $border-color;
  line-height: 3em;
  border-radius: 50%;
  height: 3.8em;
  width: 3.8em;
  margin: 0.5em;
}
*/

.calc_input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 1em;
  text-align: center;
  appearance: none;
  margin: 0;
}
