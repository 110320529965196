@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "style";
@import "buttons";
@import "calculator";
@import "modal";
@import "icons";

