// @import '~antd/dist/antd.css';

$font-color: #272727;
$body-color: #fff;
$contrast-color: #eee;

$hilight-color: #171717;

$summary_bg: #fff;
$summary_color: #111;
$summary_list_bg: #efefef;
$summary_total_bg: #fff;

$status-bg: #efc346;
$header-bg: #2f2f2f;
$header-color: #f4f4f4;

$link-accent-color: #f4f4f4;
$link-color: #efc346;

$header_height: 75px;
$status_height: 150px;

$left_height: 210px;

$summary_header_height: 50px;
$summary_footer_height: 181px;
$summary_width: 400px;

@function cols-width() {
	@return calc(100% / 4);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
}

/*
font-family: system-ui, -apple-system, "Open Sans", "Source+Sans+Pro",
		"Roboto", "Helvetica", sans-serif;
		*/
body {
	background: $body-color;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	color: $font-color;
}

.btn-selected {
	background: $status-bg;
}

a {
	color: $link-color;
	transition: color 0.3s;
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	color: $link-accent-color;
}
a:active {
	color: $link-accent-color;
}
a:link,
a:visited {
	color: $link-color;
}

a.dark {
	color: #2f2f2f;
	transition: color 0.3s;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #2f2f2f;
	}
	&:active {
		color: #2f2f2f;
	}
	&:link,
	&:visited {
		color: #2f2f2f;
	}
}

.accent {
	color: $link-color;
}

.pad {
	padding: 20px;
}

.full-w {
	width: 100%;
}

.full-h {
	height: 100%;
}

.logo {
	width: 176px;
	height: 50px;
	margin: 10px;
}

.logo-login {
	width: auto;
}

//layout
.bg_yellow {
	background-color: #efc346;
}

.layout__login {
	align-self: center;
	width: 400px;
	max-width: 90%;

	color: $font-color;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em;
}

.wrap {
	margin: 0;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
}
.layout__body {
	width: 100vw;
	flex: 1;
	display: flex;
	max-height: 100%;
}

.layout_v {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	max-height: 100%;
}

.layout__content {
	display: flex;
	flex-direction: row;
	// background: $body-color;
	// background: rgba(255, 255, 255, .5);
	overflow: hidden;
}

.fh {
	flex: 1;
	display: flex;
}

.layout_h {
	flex: 1;
	display: flex;
}

.layout__columns {
	width: cols-width();
	border-right: 1px solid #f5f5f5;
	background-color: #fff;
	transition: transform 500ms ease-in-out;
	padding: 5px;
	overflow: scroll;
}

.column_width {
	background-color: #fff;
}
.column {
	padding-top: 2em;
	height: 100%;
	margin: 0;
	padding: 0;
	// overflow-y: scroll;
}
//SECTIONS

.header {
	background: $header-bg;
	color: $header-color;
	padding: 0.4em;
	width: 100vw;
	max-height: $header_height;
	flex-basis: $header_height;

	display: flex;
	align-items: center;
	justify-content: space-between;

	letter-spacing: 0.8px;
	font-size: 14px;
	font-weight: 600;

	// position: fixed;
}

.floating_footer {
	position: absolute;
	right: 20px;
	bottom: 20px;
	height: 80px;

	.label {
		letter-spacing: 0.8px;
		font-size: 14px;
	}

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.footer {
	color: $font-color;
	padding: 0.4em;
	width: 100vw;
	height: 50px;

	display: flex;
	background: #fff;
	justify-content: center;
	align-items: center;
}

.status {
	background: $status-bg;
	background: linear-gradient(0deg, #f4bb00 0%, #fcd142 100%);
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	color: $font-color;
	padding: 5px;
	width: 100%;
	// height: $status_height;
	min-height: $status_height;
	display: flex;
	flex-direction: column;
	position: relative;
}

.status-text {
	margin: 0 5px;
}
.status-content {
	margin-left: 0.5rem;
	margin-right: 1.5rem;
}
.status-title {
	font-size: 1.3em;
	font-weight: bold;
}
.status-subtitle {
	margin-top: 4px;
	font-size: 15px;
	text-transform: uppercase;
}

//SUMMARY
.layout__summary {
	flex: 0 0 $summary_width;
	overflow: hidden;
	background-color: $summary_bg;
	color: $summary_color;
	border-right: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
	display: flex;
	flex-direction: column;

	z-index: 1;
}

.summary_title {
	text-align: center;
	font-size: 1.1em;
	letter-spacing: 0.5px;
	font-weight: bold;
	margin-top: 22px;
	margin-bottom: 12px;
}

.summary_total {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin-bottom: 4px;

	margin-top: 20px;
}

.summary_currency {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 1px;
}

.summary_currency_striked {
	@extend .summary_currency;
	font-size: 18px;
	text-decoration: line-through;
}

.summary_list {
	width: 100%;
	background: #f9f9f9;
	height: calc(
		100vh - #{$header_height + $summary_header_height + $summary_footer_height +5}
	);
	flex: 1;
	overflow-y: scroll;
}

.summary__header {
	background-color: #f9f9f9;
	flex: 0 0 $summary_header_height;
}

.summary_footer {
	width: $summary_width;
	color: $font-color;
	background: $summary_total_bg;
	height: $summary_footer_height;
	box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.11);
	padding: 0.5em;

	display: flex;
	align-items: center;
	flex-direction: column;
}

.summary_list__item_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 3;
	flex: 1;
	@include flex;
	flex-direction: column;
}

.summary_list__item {
	position: relative;
	width: 100%;
	padding: 15px 0;

	border-bottom: 1px solid #d8d8d8;
	margin: 0;

	.icon {
		align-self: flex-start;
		margin-top: 4px;
		width: 36px;
		font-size: 12px;
		text-align: center;
	}
	.title {
		align-self: flex-start;
		font-size: 14px;
		font-weight: bold;
		line-height: 19px;
		text-align: left;
		margin-right: 10px;
	}
	.price {
		align-self: flex-start;
		font-size: 14px;
		font-weight: bold;
		line-height: 19px;
		text-align: right;
		margin-right: 10px;
	}
	.descr {
		margin-top: 4px;
		font-size: 12px;
		line-height: 19px;
		text-align: left;
		text-transform: uppercase;
	}
}

.summary_list__item:hover {
	background: #f4f4f4;
}

.square {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	display: inline-block;
}
.dot {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.status__input {
	align-self: centr;
	justify-self: center;
	width: 100%;
	max-width: 250px;
	height: 40px;
	border: 1px solid #2f2f2f;
	outline: 0;

	box-shadow: inset 0;
	padding: 2px 10px;
	background: $status-bg;
	margin: 10px;
}

.result-message {
	font-size: 1.2em;
	color: #fc0;
}

.redBorder {
	border-left: 2px solid red;
	border-right: 2px solid red;
}

.greenBorder { }
