$modal-text: #111;
$modal-content-bg: #fff;

$modal-content-button-bg: #232527;
$modal-content-button-color: #fff;

$modal-overlay-button-bg: #232527;
$modal-overlay-button-color: #fff;

@import "icomoon-variables";

.modal-container {
  position: relative;
  display: none;

  .modal-content,
  .modal-backdrop {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
  }

  .modal-content-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    margin: 0 0.4em;
    padding: 0 0.4em;

    background: $modal-content-button-bg;
    color: $modal-content-button-color;
    width: auto;
    border-radius: 0.4em;
    font-size: 0.8em;

    height: 2em;
    width: auto;
    min-width: 4em;

    font-weight: normal;
    &:hover {
      background: darken($modal-content-button-bg, 5%);
    }
  }

  .modal-content-footer {
    margin: 10px;

    // align-items: flex-end;
    // justify-content: flex-end;
    // align-self: flex-end;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .modal-content-body {
    flex: 1;
    @include flex;
  }
  &.active {
    display: block;
    .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s ease-in;

      @include flex;
    }
    .modal-content {
      color: $modal-text;
      position: relative;
      opacity: 1;
      background-color: $modal-content-bg;
      max-width: 80%;
      max-height: 90%;
      min-width: 36em;
      min-height: 24em;
      height: auto;
      width: auto;
      padding: 0.6em 2em;

      border-radius: 0.4em;
      z-index: 9;
      pointer-events: auto;
      cursor: auto;
      visibility: visible;
      box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.6);

      .modal-close {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        // color: $modal-content-button-color;
        // background: $modal-content-button-bg;
        // font-size: 1em;

        color: #2f2f2f;
        font-weight: bold;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        @include flex;
        // &:hover {
        //   background: darken($modal-content-button-bg, 5%);
        // }
      }
    }
    .modal-overlay {
      color: #fff;
      position: relative;
      opacity: 1;
      background: none;

      max-width: 80%;
      max-height: 90%;
      width: 42em;
      height: 30em;
      padding: 0.6em 2em;

      border-radius: 0.4em;
      z-index: 9;
      pointer-events: auto;
      cursor: auto;
      visibility: visible;

      .modal-close {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        // color: $modal-overlay-button-color;
        // background: $modal-overlay-button-bg;
        // font-size: 1em;
        color: #2f2f2f;
        font-weight: bold;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;

        @include flex;
        // &:hover {
        //   background: lighten($modal-overlay-button-bg, 5%);
        // }
      }
    }
  }
}
