$margin-y: 0.75em;
$margin-x: 0.8em;
$btn-h: 50px;

$btn-action-w: 128px;

$btn-action-w2: 167px;
$btn-action-h: 50px;
$btn-action-fontsize: 1em;

$btn-radius: 0;
$btn-action-radius: 0;
/*
$btn-radius: 41.5px;
$btn-action-radius: 30px;
*/
.btn-oval {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: #fcd142;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #2f2f2f;

  @include flex;
}

.btn-oval--white {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #2f2f2f;

  @include flex;
}

.btn-oval--dark {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background: linear-gradient(180deg, #464646 2.95%, #2f2f2f 100%);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-width: 0;
  font-weight: bold;
  color: #f4f4f4;

  @include flex;
}

.btn-action {
  cursor: pointer;
  height: $btn-action-h;
  width: $btn-action-w;
  border-radius: $btn-action-radius;
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: $btn-action-fontsize;
  color: #2f2f2f;

  border-width: 0;
}

.btn-action-summary {
  width: $btn-action-w2;
}

.btn-action--dark {
  cursor: pointer;
  display: inline-block;
  height: $btn-action-h;
  width: $btn-action-w;
  border-radius: $btn-action-radius;
  border-width: 0;
  background: linear-gradient(180deg, #464646 2.95%, #2f2f2f 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #f8d23f;
  font-size: $btn-action-fontsize;
}
.btn-action--light {
  cursor: pointer;
  display: inline-block;
  height: $btn-action-h;
  width: $btn-action-w;
  border-radius: $btn-action-radius;
  border-width: 0;
  background: #d8d8d8;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #2f2f2f;
  font-size: $btn-action-fontsize;
}

.btn {
  cursor: pointer;
  height: $btn-action-h;
  border-radius: $btn-radius;
  font-size: $btn-action-fontsize;
  color: #2f2f2f;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: 500;
  display: block;

  border-width: 0;
}

.btn-small {
  width: $btn-action-w;
}

.btn-big {
  width: $btn-action-w2;
}

.btn-block {
  width: 100%;
}

.btn-squared {
  width: $btn-action-h;
}

.btn-selected {
  cursor: pointer;
  height: $btn-action-h;
  border-radius: $btn-radius;
  font-size: $btn-action-fontsize;
  color: #2f2f2f;
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: $btn-action-fontsize;
  font-weight: bold;
  opacity: 1;
  border-width: 0;
}

.btn-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em;
  margin-right: 0.5em;

  div {
    width: 15%;
    text-align: left;
  }
  span {
    width: 30%;
    text-align: left;
  }
}

.btn-step {
  cursor: pointer;
  width: calc(100% - #{$margin-x + $margin-x});
  margin: $margin-y auto;
  max-width: 100%;
  height: $btn-h;
  border-radius: $btn-radius;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  font-weight: 700;
  display: block;
  font-size: $btn-action-fontsize;
  color: #2f2f2f;
  opacity: 1;
  border-width: 0;
}

.btn-step-dimmed {
  opacity: 0.5;
}
.btn-step-selected {
  cursor: pointer;
  width: calc(100% - #{$margin-x + $margin-x});
  height: $btn-h;
  margin: $margin-y auto;
  max-width: 100%;
  border-radius: $btn-radius;
  background: linear-gradient(180deg, #ffe17f 0%, #fcd142 100%);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: $btn-action-fontsize;
  color: #2f2f2f;
  font-weight: 700;
  display: block;
  border-width: 0;
  opacity: 1;
  color: #2f2f2f;
}

.btn-step-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  .icon {
    font-size: 2.5em;
  }
}

.btn-link {
  color: $link-color;
  transition: color 0.3s;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 1em;
  font-weight: 700;

  & :hover {
    color: $link-accent-color;
  }
  & :active {
    color: $link-accent-color;
  }
  & :visited {
    color: $link-color;
  }
}

.btn-link-dark {
  color: #111;
  transition: color 0.3s;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 1em;
  font-weight: 700;

  & :hover {
    color: #333;
  }
  & :active {
    color: #333;
  }
  & :visited {
    color: #111;
  }
}

.btn-simple {
  color: #111;
  transition: color 0.3s;
  cursor: pointer;
  background: white;
  border: 1px solid #ddd;

  & :hover {
    color: #333;
    background: #f4f4f4;
  }
  & :active {
    color: #333;
    background: white;
  }
  & :visited {
    color: #111;
    background: white;
  }
}

.btn-round {
  transition: color 0.3s;
  cursor: pointer;
  background-color: #fcd142;
  border: none;
  border-radius: 50%;
  font-size: 10;
  color: #111;
  fill: #111;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  width: 40px;
  height: 40px;

  margin: auto 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.btn-round:hover {
  border: 1px solid #f4f4f4;
}

.btn-round--dark {
  @extend .btn-round;
  background-color: #2f2f2f;
  font-size: 10;
  color: #fff;
}

.btn-round--dark:hover {
  border: 1px solid #fff;
}

button,
.btn,
.btn-simple,
.btn-link,
.btn-link-dark,
.btn-selected,
.btn-action,
.btn-action--dark,
.btn-oval,
.btn-oval--dark,
.btn-step,
.btn-step--checked,
.btn-action:focus {
  outline: 2;
  cursor: pointer;
}
